@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");

@font-face {
    font-family: "Title_font";
    src: url("../fonts/titlefont.ttf");
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    color: inherit;
    text-decoration: none;
    font-family: "Space Grotesk", sans-serif;
    transition: all 0.5s ease;
}

.title-font {
    font-family: "Title_font", sans-serif;
}

body {
    background-color: #060606;

}

.FormPage {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: white;
    padding: 90px 0;
    gap: 130px;
}

.HeaderText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
}

.HeaderText h1 {
    width: 915px;
    font-size: 82px;
    font-weight: 700;
    line-height: 100px;
    color: #C0FF03;
    text-align: center;
    font-family: "Title_font", sans-serif;
}

.HeaderText p {
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 1px;
    text-align: center;
    color: #FDFDFD;
    width: 470px;

}

.MainInputs {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 13px;
}

.MainInputs input {
    background-color: transparent;
    outline: none;
    width: 326px;
    height: 84px;
    border-radius: 100px;
    border: 1px solid #FDFDFD;
    color: #FDFDFD;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    padding: 0px 16px;
}

:root {
    --crl: #0000
}

.button {
    cursor: pointer;
    width: 326px;
    height: 84px;
    line-height: 1;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .75rem;
    background-color: var(--crl);
    border-radius: 10rem;
    font-weight: 400;
    padding: .75rem 1.5rem;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: background-color .3s;
    font-size: 24px;
    transition: all 0.4s ease;
    border: 1px solid #9dd003;
    outline: none;
}

.button:active {
    scale: 80%;
    transition: all 0.4s ease;
}

.button__icon-wrapper {
    flex-shrink: 0;
    width: 38px;
    height: 38px;
    position: relative;
    color: #fff;
    background-color: #9dd003;
    border-radius: 50%;
    display: grid;
    place-items: center;
    overflow: hidden;
}

.button:hover {
    background-color: #9dd003;
}

.button:hover .button__icon-wrapper {
    color: #9dd003;
    background-color: #fff;

}

.button__icon-svg--copy {
    position: absolute;
    transform: translate(-150%, 150%);
}

.button:hover .button__icon-svg:first-child {
    transition: transform .3s ease-in-out;
    transform: translate(150%, -150%);
}

.button:hover .button__icon-svg--copy {
    transition: transform .3s ease-in-out .1s;
    transform: translate(0);
}

.Footer {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 58px;
    margin-top: 130px;
}

.FooterInfoText {
    width: 645px;
    height: 52px;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    letter-spacing: 1px;
}

.FooterNetworks {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 85px;
    margin-top: 50px;
    margin-bottom: 130px;
}

.Networks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.Network {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.Network p {
    font-family: "Title_font", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
}

.line-component {
    width: 80%;
    height: 2px;
    position: relative;
    overflow: hidden;
    background-color: #bfff001c;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation: gren 10s ease infinite;
        // background: linear-gradient(to left, #ffffff0d, 50% #c0ff03, #ffffff0d);
        background: linear-gradient(to left, #ffffff00, #c0ff038b, #ffffff00);
    }
}

@keyframes gren {
    0% {
        translate: -100%;
    }

    50% {
        translate: 0%;
    }

    100% {
        translate: 100%;
    }
}

@media (max-width:1200px) and (min-width:1024px)  {
    .FooterNetworks {
        gap: 30px;
    }

    .Networks {
        gap: 15px;
    }

    .Network {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
    }

    .Network p {
        font-family: "Title_font", sans-serif;
        font-size: 19px;
        font-weight: 700;
        line-height: 29px;
    }

    .HeaderText h1 {
        width: 915px;
        font-size: 70px;
        font-weight: 700;
        line-height: 100px;
        color: #C0FF03;
        text-align: center;
        font-family: "Title_font", sans-serif;
    }

    .HeaderText p {
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 1px;
        text-align: center;
        color: #FDFDFD;
        width: 470px;

    }

    .MainInputs {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 13px;
    }

    .MainInputs input {
        background-color: transparent;
        outline: none;
        width: 306px;
        height: 70px;
        border-radius: 100px;
        border: 1px solid #FDFDFD;
        color: #FDFDFD;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        padding: 0px 16px;
    }

    .button {
        cursor: pointer;
        width: 306px;
        height: 70px;
        line-height: 1;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .75rem;
        background-color: var(--crl);
        border-radius: 10rem;
        font-weight: 400;
        padding: .75rem 1.5rem;
        padding-left: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: background-color .3s;
        font-size: 24px;
        transition: all 0.4s ease;
        border: 1px solid #9dd003;
        outline: none;
    }
}

@media (max-width:1023px) and (min-width:769px) {
    .MainInputs {
        width: 660px;
        flex-wrap: wrap;
    }

    .FooterNetworks {
        gap: 30px;
        flex-wrap: wrap;
    }

    .Networks {
        gap: 15px;
    }

    .Network {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
    }

    .Network p {
        font-family: "Title_font", sans-serif;
        font-size: 19px;
        font-weight: 700;
        line-height: 29px;
    }

    .HeaderText h1 {
        width: 769px;
        font-size: 60px;
        font-weight: 700;
        line-height: 70px;
        color: #C0FF03;
        text-align: center;
        font-family: "Title_font", sans-serif;
    }

    .HeaderText p {
        font-size: 17px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.5px;
        text-align: center;
        color: #FDFDFD;
        width: 470px;

    }

    .MainInputs {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 13px;
    }

    .MainInputs input {
        background-color: transparent;
        outline: none;
        width: 306px;
        height: 70px;
        border-radius: 100px;
        border: 1px solid #FDFDFD;
        color: #FDFDFD;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        padding: 0px 16px;
    }

    .button {
        cursor: pointer;
        width: 95%;
        height: 70px;
        line-height: 1;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .75rem;
        background-color: var(--crl);
        border-radius: 10rem;
        font-weight: 400;
        padding: .75rem 1.5rem;
        padding-left: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: background-color .3s;
        font-size: 24px;
        transition: all 0.4s ease;
        border: 1px solid #9dd003;
        outline: none;
    }

    .FooterInfoText {
        width: 645px;
        height: 52px;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        text-align: center;
        letter-spacing: 0.5px;
    }
}

@media (max-width:768px) and (min-width:481px) {
    .MainInputs {
        width: 100%;
        flex-wrap: wrap;
    }

    .FooterNetworks {
        gap: 30px;
        flex-wrap: wrap;
    }

    .Networks {
        gap: 15px;
    }

    .Network {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
    }

    .Network p {
        font-family: "Title_font", sans-serif;
        font-size: 15px;
        font-weight: 700;
        line-height: 29px;
    }

    .HeaderText h1 {
        width: 490px;
        font-size: 45px;
        font-weight: 700;
        line-height: 70px;
        color: #C0FF03;
        text-align: center;
        font-family: "Title_font", sans-serif;
    }

    .HeaderText p {
        font-size: 15px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.5px;
        text-align: center;
        color: #FDFDFD;
        width: 470px;

    }

    .MainInputs {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 13px;
    }

    .MainInputs input {
        background-color: transparent;
        outline: none;
        width: 90%;
        height: 60px;
        border-radius: 100px;
        border: 1px solid #FDFDFD;
        color: #FDFDFD;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        padding: 0px 16px;
    }

    .button {
        cursor: pointer;
        width: 90%;
        height: 65px;
        line-height: 1;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .75rem;
        background-color: var(--crl);
        border-radius: 10rem;
        font-weight: 400;
        padding: .75rem 1.5rem;
        padding-left: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: background-color .3s;
        font-size: 24px;
        transition: all 0.4s ease;
        border: 1px solid #9dd003;
        outline: none;
    }

    .FooterInfoText {
        width: 481px;
        height: 52px;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        text-align: center;
        letter-spacing: 0.5px;
    }

    .FormPage {
        gap: 100px;
    }
}

@media (max-width:480px) {
    .MainInputs {
        width: 100%;
        flex-wrap: wrap;
    }

    .FooterNetworks {
        gap: 30px;
        flex-wrap: wrap;
    }

    .Networks {
        gap: 15px;
    }

    .Network {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
    }

    .Network p {
        font-family: "Title_font", sans-serif;
        font-size: 15px;
        font-weight: 700;
        line-height: 29px;
    }

    .HeaderText h1 {
        width: 95%;
        font-size: 35px;
        font-weight: 700;
        line-height: 50px;
        color: #C0FF03;
        text-align: center;
        font-family: "Title_font", sans-serif;
    }

    .HeaderText p {
        width: 95%;
        font-size: 15px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.5px;
        text-align: center;
        color: #FDFDFD;
    }

    .MainInputs {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 13px;
    }

    .MainInputs input {
        background-color: transparent;
        outline: none;
        width: 90%;
        height: 50px;
        border-radius: 100px;
        border: 1px solid #FDFDFD;
        color: #FDFDFD;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        padding: 0px 16px;
    }

    .button {
        cursor: pointer;
        width: 90%;
        height: 50px;
        line-height: 1;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .75rem;
        background-color: var(--crl);
        border-radius: 10rem;
        font-weight: 400;
        padding: .75rem 1.5rem;
        padding-left: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: background-color .3s;
        font-size: 24px;
        transition: all 0.4s ease;
        border: 1px solid #9dd003;
        outline: none;
    }

    .FooterInfoText {
        width: 95%;
        height: 52px;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        text-align: center;
        letter-spacing: 0.5px;
    }

    .FormPage {
        gap: 100px;
    }
}